/*
 * @Author: a3802 253092329@qq.com
 * @Date: 2024-08-27 02:22:27
 * @LastEditors: a3802 253092329@qq.com
 * @LastEditTime: 2024-08-27 02:36:32
 * @FilePath: \luckycanvas\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import { Form, Button } from 'vant';
import { Toast } from 'vant';
// import { decode } from 'js-base64';
import App from './App.vue'
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import './api/request.js';
import 'vant/lib/index.css';
// import 'amfe-flexible';
import './libs/rem.js';
import router from './router/index';
import "vue3-video-play/dist/style.css"; // 引入css

const app = createApp(App)
app.use(router).use(Toast).use(Form).use(Button);
app.mount('#app')